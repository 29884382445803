
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  Back,
  Section as CommonSection,
  GameCard,
  LayoutFooter,
  SectionColors,
} from "@/components";
import { useStore, ActionTypes, AdvertisementSpots } from "@/store";
import { Game } from "@/store/state";
import getEnv from "@/utils/env";

export default defineComponent({
  components: {
    Back,
    CommonSection,
    GameCard,
    LayoutFooter,
  },

  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const route = useRoute();
    const apiURI = getEnv("VUE_APP_API_ENDPOINT");
    const imdURI = getEnv("VUE_APP_IMD_ENDPOINT")

    const currentGame = computed(() =>
      store.getters.gameByID(route.params.id as string)
    );

    const alsoFunGames = computed(() =>
      store.getters.alsoFunGames(route.params.id as string)
    );

    const flightData = computed(() =>
      store.getters.flightData
    );

    const reload = async function () {
      pageReady.value = false;

      await store.dispatch(
        ActionTypes.GetAlsoFunGames,
        route.params.id as string
      );
      await store.dispatch(
        ActionTypes.GetAdvertisement,
        AdvertisementSpots.GameFooter
      );

      pageReady.value = true;
    };

    const pageReady = ref(false);

    onMounted(() => store.dispatch(ActionTypes.GetGames));
    onMounted(() => reload());

    watch(
      () => route.params.id,
      () => reload()
    );

    return { t, locale, currentGame, alsoFunGames, pageReady, route, apiURI, flightData, imdURI };
  },
  data() {
    return {
      fullscreen: false,
      nativeFullscreen: false,
      adSpotFooter: AdvertisementSpots.GameFooter,
      mainColor: SectionColors.GamesMainColor,
      secondaryColor: SectionColors.GamesSecondaryColor,
    };
  },
  methods: {
    iOS() {
      return [
        'iPhone Simulator',
        'iPod Simulator',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
    },
    iPad() {
      return [
        'iPad Simulator',
        'iPad',
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    showFullscreen(game: Game) {
      // iOS
      if (this.iOS()) {
        return !game.hideFullscreen.iOS
      }

      // iPad
      if (this.iPad()) {
        return !game.hideFullscreen.iPad
      }

      // Android
      var userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        return !game.hideFullscreen.android;
      }

      // Unknown / Desktop
      return !game.hideFullscreen.desktop;
    },
     setFullscreen() {
      // eslint-disable-next-line
      const elem: any = document.getElementById("game-iframe-container");

      if (elem == null) return;

      if (elem.requestFullscreen) {
        this.$data.nativeFullscreen = true;
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        this.$data.nativeFullscreen = true;
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        this.$data.nativeFullscreen = true;
        elem.msRequestFullscreen();
      } else {
        this.$data.fullscreen = true;
      }
    },
    exitFullscreen() {
      // eslint-disable-next-line
      const d: any = document;
      if (d.exitFullscreen) {
        this.$data.nativeFullscreen = false;
        d.exitFullscreen();
      } else if (d.webkitExitFullscreen) {
        this.$data.nativeFullscreen = false;
        d.webkitExitFullscreen();
      } else if (d.msExitFullscreen) {
        this.$data.nativeFullscreen = false;
        d.msExitFullscreen();
      } else {
        this.$data.fullscreen = false;
      }
    },
  },
});
