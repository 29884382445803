import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06ac4a12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "video-wrapper",
  ref: "video-wrapper"
}
const _hoisted_2 = ["width", "height", "autoplay"]
const _hoisted_3 = ["data-width", "data-height"]
const _hoisted_4 = ["data-pxeasy-action"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.adEngine != undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("video", {
          ref: "player",
          tabindex: "0",
          controls: "",
          width: _ctx.playerWidth,
          height: _ctx.playerHeight,
          autoplay: _ctx.adEngine == 'gladi8tor',
          id: "vpl",
          class: "max-width"
        }, null, 8, _hoisted_2),
        (_ctx.adEngine == 'imd')
          ? (_openBlock(), _createElementBlock("ins", {
              key: 0,
              "data-type": "vast",
              "data-width": _ctx.playerWidth,
              "data-height": _ctx.playerHeight,
              "data-format": "video-pre-roll",
              "data-zone": "1",
              "data-section": "998fb2c7-8852-4f3a-bc1e-eb3893cfe7ec",
              "data-action": "imd.videoCallback",
              "data-id": "vpl"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.adEngine == 'imm')
          ? (_openBlock(), _createElementBlock("ins", {
              key: 1,
              "data-pxeasy-type": "VAST",
              "data-pxeasy-format": "video-pre-roll",
              "data-pxeasy-section": "gaming_streams",
              "data-pxeasy-action": _ctx.callback,
              "data-pxeasy-id": "vpl"
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ], 512))
    : _createCommentVNode("", true)
}