import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5cb1790"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "stream-container"
}
const _hoisted_2 = { class: "back-button-wrapper" }
const _hoisted_3 = { class: "titlebar-wrapper max-width" }
const _hoisted_4 = { class: "titlebar" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "sub-title" }
const _hoisted_7 = { class: "description max-width" }
const _hoisted_8 = { class: "card-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back = _resolveComponent("back")!
  const _component_stream_player = _resolveComponent("stream-player")!
  const _component_common_section = _resolveComponent("common-section")!
  const _component_stream_card = _resolveComponent("stream-card")!
  const _component_layout_footer = _resolveComponent("layout-footer")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cssProps)
  }, [
    (_ctx.currentStream != undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_back, {
              class: "back-button max-width",
              text: _ctx.t('streams.back-to-all-streams'),
              location: { name: 'Streams' }
            }, null, 8, ["text"])
          ]),
          _createVNode(_component_stream_player, {
            src: _ctx.currentStream.uri,
            class: "player max-width"
          }, null, 8, ["src"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.currentStream.title), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.currentStream.subTitle), 1)
            ])
          ]),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.currentStream.description), 1),
          _createVNode(_component_common_section, {
            colorLeft: _ctx.mainColor,
            colorRight: _ctx.secondaryColor,
            class: "stream-section c-section",
            text: _ctx.t('streams.also-fun')
          }, null, 8, ["colorLeft", "colorRight", "text"]),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alsoFunStreams, (stream) => {
              return (_openBlock(), _createBlock(_component_stream_card, {
                class: "grid-card",
                key: stream.id,
                stream: stream,
                type: "image"
              }, null, 8, ["stream"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.pageReady)
      ? (_openBlock(), _createBlock(_component_layout_footer, {
          key: 1,
          spot: _ctx.adSpotFooter
        }, null, 8, ["spot"]))
      : _createCommentVNode("", true)
  ], 4))
}