import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/games/fullscreen-icon.svg'
import _imports_1 from '@/assets/games/exit-fullscreen-icon.svg'


const _withScopeId = n => (_pushScopeId("data-v-781f3722"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "game-container"
}
const _hoisted_2 = { class: "back-button-wrapper" }
const _hoisted_3 = { class: "padding-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "padding-wrapper" }
const _hoisted_6 = { class: "titlebar max-width" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "category" }
const _hoisted_9 = { class: "padding-wrapper" }
const _hoisted_10 = { class: "description max-width" }
const _hoisted_11 = { class: "card-grid max-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_back = _resolveComponent("back")!
  const _component_common_section = _resolveComponent("common-section")!
  const _component_game_card = _resolveComponent("game-card")!
  const _component_layout_footer = _resolveComponent("layout-footer")!

  return (_ctx.currentGame != undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_back, {
            class: "back-button max-width",
            text: _ctx.t('games.back-to-all-games'),
            location: { name: 'Games' }
          }, null, 8, ["text"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            id: "game-iframe-container",
            class: _normalizeClass(["max-width", {
          'game-iframe-container': _ctx.currentGame.orientation != 'portrait',
          'game-iframe-container-portrait':
            _ctx.currentGame.orientation == 'portrait',
          fullscreen: _ctx.fullscreen,
        }])
          }, [
            (
            (_ctx.showFullscreen(_ctx.currentGame)) &&
            (!_ctx.fullscreen || !_ctx.nativeFullscreen)
          )
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "fullscreen-icon",
                  src: _imports_0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setFullscreen()))
                }))
              : _createCommentVNode("", true),
            (
            (_ctx.showFullscreen(_ctx.currentGame)) &&
            (_ctx.fullscreen || _ctx.nativeFullscreen)
          )
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "fullscreen-icon",
                  src: _imports_1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.exitFullscreen()))
                }))
              : _createCommentVNode("", true),
            _createElementVNode("iframe", {
              ref: "game",
              class: "game-iframe",
              src: _ctx.currentGame.entryPoint + '?language=' + encodeURIComponent(_ctx.locale) + '&storage=' + encodeURIComponent(_ctx.apiURI + '/games/' + _ctx.$route.params.id + '/storage') + '&airline=' + encodeURIComponent(_ctx.flightData?.airline || '' ) + '&origin=' + encodeURIComponent(_ctx.flightData?.origin || '') + '&destination=' + encodeURIComponent(_ctx.flightData?.destination || '') + '&flightNumber=' + encodeURIComponent(_ctx.flightData?.flightNumber || '') + '&imd=' + encodeURIComponent(_ctx.imdURI?.replaceAll('/assets?path=engine.js', '') || ''),
              frameborder: "no",
              border: "0",
              scrolling: "no"
            }, null, 8, _hoisted_4)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.currentGame.name), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.currentGame.category), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.currentGame.description), 1)
        ]),
        _createVNode(_component_common_section, {
          "common-section": "",
          colorLeft: _ctx.mainColor,
          colorRight: _ctx.secondaryColor,
          class: "game-section",
          text: _ctx.t('games.also-fun')
        }, null, 8, ["colorLeft", "colorRight", "text"]),
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alsoFunGames, (game) => {
            return (_openBlock(), _createBlock(_component_game_card, {
              class: "grid-card",
              key: game.id,
              game: game,
              type: "image"
            }, null, 8, ["game"]))
          }), 128))
        ]),
        (_ctx.pageReady)
          ? (_openBlock(), _createBlock(_component_layout_footer, {
              key: 0,
              spot: _ctx.adSpotFooter
            }, null, 8, ["spot"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}