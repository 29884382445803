import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c1622e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-format", "data-section", "data-zone"]
const _hoisted_2 = ["data-pxeasy-format", "data-pxeasy-whenmobile", "data-pxeasy-width", "data-pxeasy-section", "data-pxeasy-zone"]
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { class: "image" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 4,
  class: "microsite"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_ad = _resolveComponent("video-ad")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "ad",
    class: _normalizeClass([_ctx.pointer ? _ctx.imdZoneID == '2' ?  _ctx.clientWidth > 800 ? 'pointer a-card-mpu' : 'pointer' : 'pointer a-card-lb' : _ctx.imdZoneID == '2' ? _ctx.clientWidth > 800 ? '' : 'a-card-mpu' : 'a-card-lb', "a-card"]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openMicroSite()))
  }, [
    (_ctx.adEngine == 'imd')
      ? (_openBlock(), _createElementBlock("ins", {
          key: 0,
          ref: "imd",
          "data-type": "banner",
          "data-format": _ctx.imdZoneID == '2' ? '300x250' : '728x90',
          "data-zindex": "9",
          "data-width": "100%",
          "data-height": "100%",
          "data-section": _ctx.imdSection,
          "data-zone": _ctx.imdZoneID
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.adEngine == 'imm')
      ? (_openBlock(), _createElementBlock("ins", {
          key: 1,
          ref: "imm",
          "data-pxeasy-type": "banner",
          "data-pxeasy-format": _ctx.pxZoneID == '2' ? 'iab-mpu' : 'iab-leaderboard',
          "data-pxeasy-zindex": "9",
          "data-pxeasy-whenmobile": 
        _ctx.pxZoneID == '2' ? 'iab-mpu' : 'iab-smartphone-static-banner'
      ,
          "data-pxeasy-width": _ctx.pxZoneID == '2' && _ctx.clientWidth > 800 ? '160px' : '100%',
          "data-pxeasy-height": "100%",
          "data-pxeasy-section": _ctx.pxSection,
          "data-pxeasy-zone": _ctx.pxZoneID
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (
        _ctx.adEngine == 'gladi8tor' &&
        _ctx.advertisement != undefined &&
        _ctx.advertisement.extension == 'mp4'
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_video_ad, { advertisement: _ctx.advertisement }, null, 8, ["advertisement"])
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.adEngine == 'gladi8tor' &&
        _ctx.advertisement != undefined &&
        _ctx.advertisement.extension == 'jpg'
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.adLoaded && _ctx.adLoaded(...args))),
              src: _ctx.advertisement.uri
            }, null, 40, _hoisted_6)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.pointer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t("advertisement.click-for-more-information")), 1))
      : _createCommentVNode("", true)
  ], 2))
}