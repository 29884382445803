import getEnv from "@/utils/env";
import {
  Game,
  Games,
  Home,
  Stream,
  Streams,
  News,
  NewsItem,
  Shop,
  ShopItem,
  PrivacyPolicy,
  Frame,
  Callback,
} from "@/views";
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import { routeGuard } from "@/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:locale",
    name: "Home",
    component: Home,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/games",
    name: "Games",
    component: Games,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/games/:id",
    name: "Game",
    component: Game,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/streams",
    name: "Streams",
    component: Streams,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/streams/:id",
    name: "Stream",
    component: Stream,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/shop",
    name: "Shop",
    component: Shop,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/shop/:id",
    name: "ShopItem",
    component: ShopItem,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/news",
    name: "News",
    component: News,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/news/:id",
    name: "NewsItem",
    component: NewsItem,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/frame/:uri",
    name: "Frame",
    component: Frame,
    beforeEnter: routeGuard,
  },
  {
    path: "/:locale/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    beforeEnter: routeGuard,
  },
  {
    path: "/callback",
    name: "Callback",
    component: Callback,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect() {
      return getEnv("VUE_APP_I18N_LOCALE") || "en";
    },
  },
];

const relativePath = getEnv("VUE_APP_BASE_URI")?.replace(
  window.location.origin,
  ""
);

const router = createRouter({
  scrollBehavior() {
    return {
      top: 0,
      left: 0,
      behavior: "auto",
    };
  },
  history: createWebHashHistory(relativePath),
  routes,
});

export default router;
