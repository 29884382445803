import { ActionContext, ActionTree } from "vuex";
import {
  Mutations,
  MutationType,
  SetAdvertisementEngineBySpotPayload,
  SetAdvertisementPayload,
} from "./mutations";
import {
  Advertisement,
  State,
  Game,
  Stream,
  StreamCategory,
  Category,
  Shop,
  News,
  ShoppingCart,
  Order,
} from "./state";
import axios from "axios";
import { AdvertisementSpots } from "./getters";
import { RouteLocation } from "vue-router";
import { Fingerprint } from "./helpers";
import getEnv from "@/utils/env";
import { getBearerToken, getAccessToken } from "@/auth";

export enum ActionTypes {
  GetVersion = "GET_VERSION",
  GetFlightData = "GET_FLIGHT_DATA",

  // Games
  GetGames = "GET_GAMES",
  GetAlsoFunGames = "GET_ALSO_FUN_GAMES",
  GetTrendingGame = "GET_TRENDING_GAME",
  GetBestRatedGames = "GET_BEST_RATED_GAMES",
  GetGamesByCategory = "GET_GAMES_BY_CATEGORY",

  // Streams
  GetTrendingStream = "GET_TRENDING_STREAM",
  GetLatestStreams = "GET_LATEST_STREAMS",
  GetStreams = "GET_STREAMS",
  GetAlsoFunStreams = "GET_ALSO_FUN_STREAMS",

  // Shop
  GetTrendingShop = "GET_TRENDING_SHOP",
  GetBestSellersShop = "GET_BEST_SELLERS_SHOP",
  GetStaffPickShop = "GET_STAFF_PICK_SHOP",
  GetMegaDealsShop = "GET_MEGA_DEALS_SHOP",
  GetShopItem = "GET_SHOP_ITEM",
  AddToCart = "ADD_TO_CART",
  RemoveFromCart = "REMOVE_FROM_CART",
  OpenShopOverlay = "OPEN_SHOP_OVERLAY",
  CloseShopOverlay = "CLOSE_SHOP_OVERLAY",
  AddShoppingCartItem = "ADD_SHOPPING_CART_ITEM",
  RemoveShoppingCartItem = "REMOVE_SHOPPING_CART_ITEM",
  SendPurchaseRequest = "SEND_PURCHASE_REQUEST",

  // News
  GetTrendingNews = "GET_TRENDING_NEWS",
  GetLatestNews = "GET_LATEST_NEWS",
  GetMoreNews = "GET_MORE_NEWS",
  GetNewsByID = "GET_NEWS_BY_ID",
  GetRecommendedNewsByID = "GET_RECOMMENDED_NEWS_BY_ID",

  // Advertisements
  GetAdvertisement = "GET_ADVERTISEMENT",
  GetAdvertisements = "GET_ADVERTISEMENTS",
  AdvertisementViewed = "ADVERTISEMENT_VIEWED",
  GetAdvertisementEngineBySpot = "GET_ADVERTISEMENT_ENGINE_BY_SPOT",

  // Analytics
  PageVisited = "PAGE_VISITED",
  PageSessionStarted = "PAGE_SESSION_STARTED",
  PageSessionEnded = "PAGE_SESSION_ENDED",
}

type ActionAugments = Omit<ActionContext<State, State>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type GetAdvertisementsPayload = {
  spot: AdvertisementSpots;
  amount: number;
};

export type AdvertisementViewedPayload = {
  spot: AdvertisementSpots;
  advertisement: Advertisement;
  route: RouteLocation;
};

export type PageVisitedPayload = {
  route: RouteLocation;
};

export type ApiShopItem = {
  id: string;
  description: string;
  highlightedImage: string;
  image: string;
  price: string;
  title: string;
  tags: string[];
  variantID: string;
  category: string[];
};

export type Actions = {
  [ActionTypes.GetVersion](context: ActionAugments): void;
  [ActionTypes.GetFlightData](context: ActionAugments): void;

  // Games
  [ActionTypes.GetGames](context: ActionAugments): void;
  [ActionTypes.GetAlsoFunGames](context: ActionAugments, id: string): void;
  [ActionTypes.GetTrendingGame](context: ActionAugments): void;
  [ActionTypes.GetBestRatedGames](context: ActionAugments): void;
  [ActionTypes.GetGamesByCategory](context: ActionAugments): void;

  // Streams
  [ActionTypes.GetStreams](context: ActionAugments): void;
  [ActionTypes.GetTrendingStream](context: ActionAugments): void;
  [ActionTypes.GetLatestStreams](context: ActionAugments): void;
  [ActionTypes.GetAlsoFunStreams](context: ActionAugments, id: string): void;

  // Shop
  [ActionTypes.GetTrendingShop](context: ActionAugments): void;
  [ActionTypes.GetBestSellersShop](context: ActionAugments): void;
  [ActionTypes.GetStaffPickShop](context: ActionAugments): void;
  [ActionTypes.GetMegaDealsShop](context: ActionAugments): void;
  [ActionTypes.GetShopItem](context: ActionAugments, id: string): void;
  [ActionTypes.AddToCart](context: ActionAugments, item: Shop): void;
  [ActionTypes.RemoveFromCart](context: ActionAugments, item: Shop): void;
  [ActionTypes.OpenShopOverlay](context: ActionAugments): void;
  [ActionTypes.CloseShopOverlay](context: ActionAugments): void;
  [ActionTypes.SendPurchaseRequest](
    context: ActionAugments,
    email: string
  ): void;

  // News
  [ActionTypes.GetTrendingNews](context: ActionAugments): void;
  [ActionTypes.GetLatestNews](context: ActionAugments): void;
  [ActionTypes.GetMoreNews](context: ActionAugments): void;
  [ActionTypes.GetNewsByID](context: ActionAugments, id: string): void;
  [ActionTypes.GetRecommendedNewsByID](
    context: ActionAugments,
    id: string
  ): void;

  // Advertisements
  [ActionTypes.GetAdvertisement](context: ActionAugments, spot: string): void;
  [ActionTypes.AdvertisementViewed](
    context: ActionAugments,
    value: AdvertisementViewedPayload
  ): void;
  [ActionTypes.GetAdvertisementEngineBySpot](
    context: ActionAugments,
    spot: string
  ): void;

  // Analytics
  [ActionTypes.PageSessionStarted](context: ActionAugments): void;
  [ActionTypes.PageSessionEnded](context: ActionAugments): void;
  [ActionTypes.PageVisited](
    context: ActionAugments,
    value: PageVisitedPayload
  ): void;
};

const API_ENDPOINT = getEnv("VUE_APP_API_ENDPOINT");

export const actions: ActionTree<State, State> & Actions = {
  async [ActionTypes.GetVersion]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/version`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetVersion, response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async [ActionTypes.GetFlightData]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/flight-data`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetFlightData, response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  // Advertisements
  async [ActionTypes.GetAdvertisement]({ commit }, spot) {
    const spotUUID = convertSpotToUUID(spot);

    return axios
      .get(`${API_ENDPOINT}/advertisements/${spotUUID}`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetAdvertisement, {
          advertisement: response.data,
          spot: spot,
        } as SetAdvertisementPayload);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.AdvertisementViewed](_, value) {
    const payload = {
      date: new Date().toISOString(),
      fingerprint: await Fingerprint(),
      path: value.route.fullPath,
      advertisement: value.advertisement.id,
    };

    navigator.sendBeacon(
      `${API_ENDPOINT}/analytics/advertisement${await getAccessToken()}`,
      JSON.stringify(payload)
    );
  },
  async [ActionTypes.GetAdvertisementEngineBySpot]({ commit }, spot) {
    const spotUUID = convertSpotToUUID(spot);

    return axios
      .get(`${API_ENDPOINT}/advertisements/spots/${spotUUID}`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetAdvertisementEngineBySpot, {
          engine: response.data.adEngine,
          spot: spot,
        } as SetAdvertisementEngineBySpotPayload);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  // Games
  async [ActionTypes.GetGames]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/games`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetGames, response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetGamesByCategory]({ commit, getters, dispatch }) {
    return axios
      .get(`${API_ENDPOINT}/games/categories`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then(async (response) => {
        const categories: Category[] = [];
        response.data.forEach((category: Category) => {
          categories.push(category);
        });

        await dispatch(ActionTypes.GetGames);
        const games = getters.games;

        games.forEach((game: Game) => {
          for (const catID in categories) {
            const category = categories[catID];
            if (category.name == game.category) {
              if (category.games == null) {
                category.games = [];
              }
              category.games.push(game);
            }
          }
        });

        commit(MutationType.SetGamesByCategory, categories);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetTrendingGame]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/games/trending`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetTrendingGame, response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetAlsoFunGames](
    { commit, dispatch, getters },
    id: string
  ) {
    await dispatch(ActionTypes.GetGames);
    const games = getters.games as Game[];

    const min = 0;
    const max = games.length - 1;

    const alsoFunGames: Game[] = [];

    do {
      const random = Math.floor(Math.random() * (max - min + 1) + min);
      const game = games[random];
      const randomGamePresent = alsoFunGames.find((g) => g.id == game.id);
      if (randomGamePresent != undefined || game.id == id) {
        continue;
      }
      alsoFunGames.push(game);
    } while (alsoFunGames.length < 8);

    commit(MutationType.SetAlsoFunGames, {
      games: alsoFunGames,
      id: id,
    });
  },
  async [ActionTypes.GetBestRatedGames]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/games/best-rated`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetBestRatedGames, response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  // Streams
  async [ActionTypes.GetTrendingStream]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/streams/trending`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetTrendingStream, response.data.large);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetLatestStreams]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/streams/trending`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetLatestStreams, response.data.items);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetStreams]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/streams`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        const streams: Stream[] = [];

        response.data.forEach((category: StreamCategory) => {
          streams.push(category.large);
          category.items.forEach((stream) => {
            streams.push(stream);
          });
        });

        commit(MutationType.SetStreams, streams);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetAlsoFunStreams](
    { commit, dispatch, getters },
    id: string
  ) {
    await dispatch(ActionTypes.GetStreams);
    const streams = getters.streams as Stream[];

    const min = 0;
    const max = streams.length - 1;

    const alsoFunStreams: Stream[] = [];

    do {
      const random = Math.floor(Math.random() * (max - min + 1) + min);
      const stream = streams[random];
      const randomStreamPresent = alsoFunStreams.find((s) => s.id == stream.id);
      if (randomStreamPresent != undefined || stream.id == id) {
        continue;
      }
      alsoFunStreams.push(stream);
    } while (alsoFunStreams.length < 4);

    commit(MutationType.SetAlsoFunStreams, {
      streams: alsoFunStreams,
      id: id,
    });
  },

  // Shop
  async [ActionTypes.GetTrendingShop]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/shop`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        const item = (response.data as ApiShopItem[]).find((item) =>
          item.category.includes("trending")
        );

        if (item == undefined) {
          return;
        }

        commit(MutationType.SetTrendingShop, convertApiShopItem(item));
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetBestSellersShop]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/shop`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(
          MutationType.SetBestSellersShop,
          convertApiShopItems(
            (response.data as ApiShopItem[]).filter((item: ApiShopItem) =>
              item.category.includes("best-sellers")
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetStaffPickShop]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/shop`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(
          MutationType.SetStaffPickShop,
          convertApiShopItems(
            (response.data as ApiShopItem[]).filter((item) =>
              item.category.includes("staff-pick")
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetMegaDealsShop]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/shop`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        const item = (response.data as ApiShopItem[]).find((item) =>
          item.category.includes("mega-deals")
        );

        if (item == undefined) {
          return;
        }

        commit(MutationType.SetMegaDealShop, convertApiShopItem(item));
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetShopItem]({ commit }, id: string) {
    return axios
      .get(`${API_ENDPOINT}/shop/${id}`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(
          MutationType.SetShopItem,
          convertApiShopItem(response.data as ApiShopItem)
        );
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [ActionTypes.AddToCart]({ commit }, item: Shop) {
    commit(MutationType.AddToCart, item);
    commit(MutationType.SetShopOverlay, true);
  },
  [ActionTypes.RemoveFromCart]({ commit }, item: Shop) {
    commit(MutationType.RemoveFromCart, item);
  },
  [ActionTypes.OpenShopOverlay]({ commit }) {
    commit(MutationType.SetShopOverlay, true);
  },
  [ActionTypes.CloseShopOverlay]({ commit }) {
    commit(MutationType.SetShopOverlay, false);
  },
  async [ActionTypes.SendPurchaseRequest]({ commit, getters }, email: string) {
    const shoppingCart = getters.shoppingCart as ShoppingCart;
    const order = {
      email: email,
      products: shoppingCart.items.map<Order>((i) => {
        return {
          id: i.item.id,
          quantity: i.amount,
        };
      }),
    };

    return axios
      .post(`${API_ENDPOINT}/order`, JSON.stringify(order), {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then(() => {
        commit(MutationType.SetShopOverlay, false);
        commit(MutationType.ClearShoppingCart, undefined);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  // News
  async [ActionTypes.GetTrendingNews]({ commit }) {
    return axios
      .get(`${API_ENDPOINT}/news`, {
        headers: {
          Authorization: await getBearerToken(),
        },
      })
      .then((response) => {
        commit(MutationType.SetTrendingNews, response.data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [ActionTypes.GetLatestNews]({ commit }) {
    const response = await axios.get(`${API_ENDPOINT}/news`, {
      headers: {
        Authorization: await getBearerToken(),
      },
    });

    // Remove trending item
    response.data.splice(0, 1);

    commit(MutationType.SetLatestNews, response.data.splice(0, 2));
  },
  async [ActionTypes.GetMoreNews]({ commit }) {
    const response = await axios.get(`${API_ENDPOINT}/news`, {
      headers: {
        Authorization: await getBearerToken(),
      },
    });

    // Remove trending [0] and lastest streams [1,2]
    response.data.splice(0, 3);

    commit(MutationType.SetMoreNews, response.data);
  },
  async [ActionTypes.GetNewsByID]({ commit }, id) {
    const response = await axios.get(`${API_ENDPOINT}/news/${id}`, {
      headers: {
        Authorization: await getBearerToken(),
      },
    });

    commit(MutationType.SetNewsByID, response.data);
  },

  async [ActionTypes.GetRecommendedNewsByID]({ commit }, id) {
    const response = await axios.get(`${API_ENDPOINT}/news`, {
      headers: {
        Authorization: await getBearerToken(),
      },
    });

    const news = response.data as News[];

    const min = 0;
    const max = news.length - 1;

    const recommendedNews: News[] = [];

    do {
      const random = Math.floor(Math.random() * (max - min + 1) + min);
      const newsItem = news[random];
      const randomNewsPresent = recommendedNews.find(
        (s) => s.id == newsItem.id
      );
      if (randomNewsPresent != undefined || newsItem.id == id) {
        continue;
      }
      recommendedNews.push(newsItem);
    } while (recommendedNews.length < 6);

    commit(MutationType.SetRecommendedNews, {
      news: recommendedNews,
      id: id,
    });
  },

  // Analytics
  [ActionTypes.PageSessionStarted]({ commit }) {
    commit(MutationType.SetSessionStart, new Date());
  },
  async [ActionTypes.PageSessionEnded]({ getters }) {
    const date = (await getters.sessionStart) as Date | undefined;

    if (date != undefined) {
      const now = new Date();
      const time = new Date().getTime() - date.getTime();
      navigator.sendBeacon(
        `${API_ENDPOINT}/analytics/session${await getAccessToken()}`,
        JSON.stringify({
          start: date.toISOString(),
          end: now.toISOString(),
          fingerprint: await Fingerprint(),
          time: time / 1000,
        })
      );
    }
  },
  async [ActionTypes.PageVisited](_, value) {
    const now = new Date();
    navigator.sendBeacon(
      `${API_ENDPOINT}/analytics/pagevisited${await getAccessToken()}`,
      JSON.stringify({
        fingerprint: await Fingerprint(),
        date: now.toISOString(),
        path: value.route.fullPath,
      })
    );
  },
};

function convertApiShopItems(items: ApiShopItem[]): Shop[] {
  return items.map((item) => convertApiShopItem(item));
}

function convertApiShopItem(item: ApiShopItem): Shop {
  return {
    id: item.id,
    category: item.category,
    description: item.description,
    highlightedImage: item.highlightedImage,
    image: item.image,
    price: Number.parseFloat(item.price),
    tags: item.tags,
    title: item.title,
    variantID: item.variantID,
  } as Shop;
}

function convertSpotToUUID(spot: string): string {
  let spotUUID = "";
  switch (spot) {
    // Home
    case AdvertisementSpots.HomeTop:
      spotUUID = "d6c96560-da35-4118-a494-34be7c6e4aa9";
      break;
    case AdvertisementSpots.HomeMiddle:
      spotUUID = "47c5b9d5-38df-4647-825a-82b0b79e7173";
      break;
    case AdvertisementSpots.HomeFooter:
      spotUUID = "76d6b6f1-4d40-4a6f-9a6a-fd619fd08f13";
      break;

    // Games
    case AdvertisementSpots.GamesTop:
      spotUUID = "bbd4bc10-61be-4b83-9929-61ad7e4379aa";
      break;
    case AdvertisementSpots.GamesMiddle:
      spotUUID = "3963edb0-0bbf-439d-a48c-97b92f9fa491";
      break;
    case AdvertisementSpots.GamesFooter:
      spotUUID = "6cd35b0f-78a2-4d9f-867c-29ff5be788e9";
      break;
    case AdvertisementSpots.GameFooter:
      spotUUID = "83c8127f-a8d2-43d3-8a3e-554248f04a22";
      break;

    // Streams
    case AdvertisementSpots.StreamsTop:
      spotUUID = "01506c98-f104-4aaa-808b-94160a6f377d";
      break;
    case AdvertisementSpots.StreamsFooter:
      spotUUID = "50ef5733-6385-429b-8a62-894e68d92693";
      break;
    case AdvertisementSpots.StreamVideoPreRoll:
      spotUUID = "0e62d96b-e041-4b63-81cb-55922e8faf64";
      break;
    case AdvertisementSpots.StreamFooter:
      spotUUID = "f2081ffb-0a7a-4d18-8428-431ac9dd31e1";
      break;

    // News
    case AdvertisementSpots.NewsTop:
      spotUUID = "b5450b39-10df-485b-a1fd-d660bf77878a";
      break;
    case AdvertisementSpots.NewsFooter:
      spotUUID = "cca57f50-0323-42ef-9efd-5256a177394b";
      break;
    case AdvertisementSpots.NewsItemFooter:
      spotUUID = "ef1e4865-af2c-4336-851a-2718537e3942";
      break;

    // Shop
    case AdvertisementSpots.ShopTop:
      spotUUID = "25b7c06e-bfc1-45e6-8e77-02e4ff254fea";
      break;
    case AdvertisementSpots.ShopFooter:
      spotUUID = "b379c5bb-428b-44b9-84fe-085a98abb075";
      break;
    case AdvertisementSpots.ShopItemFooter:
      spotUUID = "232b327d-ffbe-47aa-9e1f-183afce9d857";
      break;

    default:
      // Use HomeTop as default
      spotUUID = "d6c96560-da35-4118-a494-34be7c6e4aa9";
      break;
  }

  return spotUUID;
}
