
import { defineComponent } from "vue";
import { LayoutNavigationBar, ShopOverlay } from "@/components";
import lifecycle from "page-lifecycle/dist/lifecycle.es5";
import { useStore } from "vuex";
import { ActionTypes, PageVisitedPayload } from "./store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import getEnv from "./utils/env";

export default defineComponent({
  components: {
    LayoutNavigationBar,
    ShopOverlay,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let { locale } = useI18n();

    const pxEasyEndpoint = getEnv("VUE_APP_PXEASY_ENDPOINT");
    if (pxEasyEndpoint != undefined && pxEasyEndpoint.length > 0) {
      // Check if px client external script exists, otherwise, add to head
      const pxClientScript = document.getElementById("pxClientScript");
      if (pxClientScript == null) {
        const baseURI = getEnv("VUE_APP_BASE_URI");
        const apiURI = getEnv("VUE_APP_API_ENDPOINT");

        const profilerScript = document.createElement("script");
        profilerScript.innerText = `var PXEasy_IfeLayer=window.PXEasy_IfeLayer={device:function(a){a(480>screen.width?"mobile":"desktop")},isValid(a,b){b(!0)},profile:{language:function(a){a(window.location.toString().replace("${baseURI}","").substring(1,3))},origin:function(a){fetch("${apiURI}/flightData").then(async b=>{if(b.ok&&200==b.status){var c=JSON.parse(await b.text());a(c.origin)}else{a("")}}).catch(()=>{a("")})},destination:function(a){fetch("${apiURI}/flightData").then(async b=>{if(b.ok&&200==b.status){var c=JSON.parse(await b.text());a(c.destination)}else{a("")}}).catch(()=>{a("")})}}};`;
        document.head.appendChild(profilerScript);

        let externalScript = document.createElement("script");
        externalScript.setAttribute("id", "pxClientScript");
        externalScript.setAttribute(
          "src",
          `${getEnv("VUE_APP_PXEASY_ENDPOINT")}/pxeasy/client/html5`
        );

        document.head.appendChild(externalScript);
      }
    }

    const imdEndpoint = getEnv("VUE_APP_IMD_ENDPOINT");
    if (imdEndpoint != undefined && imdEndpoint.length > 0) {
      const pxClientScript = document.getElementById("imdClientScript");
      if (pxClientScript == null) {
        const baseURI = getEnv("VUE_APP_BASE_URI");
        const apiURI = getEnv("VUE_APP_API_ENDPOINT");

        router.beforeResolve((to) => {
          (window as any).imd = {
            language: function() {
              return to.params.locale as string;
            }
          }
        });

        let externalScript = document.createElement("script");
        externalScript.setAttribute("defer", "defer")
        externalScript.setAttribute("type", "module")
        externalScript.setAttribute("id", "imdClientScript");
        externalScript.setAttribute(
          "src",
          `${getEnv("VUE_APP_IMD_ENDPOINT")}`
        );

        document.head.appendChild(externalScript);
      }
    }

    // Track user when he leaves the page or exits the page
    // https://stackoverflow.com/questions/6162188/javascript-browsers-window-close-send-an-ajax-request-or-run-a-script-on-win
    store.dispatch(ActionTypes.PageSessionStarted);

    // eslint-disable-next-line
    lifecycle.addEventListener("statechange", (event: any): void => {
      switch (event.newState) {
        case "active":
          store.dispatch(ActionTypes.PageSessionStarted);
          break;
        case "hidden":
          store.dispatch(ActionTypes.PageSessionEnded);
          break;
      }
    });

    router.afterEach((to) => {
      store.dispatch(ActionTypes.PageVisited, {
        route: to,
      } as PageVisitedPayload);
    });

    router.beforeResolve((to) => {
      locale.value = to.params.locale as string;
    });

    return { pxEasyEndpoint };
  },
});
