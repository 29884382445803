
import {
  computed,
  ref,
  defineComponent,
  watchEffect,
  onMounted,
  PropType,
  reactive,
  onBeforeUnmount,
} from "vue";
import { default as VideoAd } from "./Video.vue";
import {
  useStore,
  AdvertisementSpots,
  ActionTypes,
  AdvertisementViewedPayload,
} from "@/store";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    VideoAd,
  },
  props: {
    spot: {
      type: undefined as unknown as PropType<AdvertisementSpots>,
      required: true,
    },
  },
  data() {
    return {
      clientWidth: Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
    };
  },
  setup(props) {
    const ad = ref<HTMLDivElement>();
    const imm = ref<HTMLDivElement>();
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const route = useRoute();

    const adEngine = computed(() =>
      store.getters.advertisementEngineBySpot(props.spot)
    );
    store.dispatch(ActionTypes.GetAdvertisementEngineBySpot, props.spot);

    const advertisement = computed(() =>
      store.getters.advertisementBySpot(props.spot)
    );
    const pointer = computed(() => {
      return (
        advertisement.value != null &&
        advertisement.value.microSiteURI.length > 0
      );
    });

    const adPropVisible = reactive({ visible: false });
    watchEffect(() => {
      if (
        adPropVisible.visible &&
        advertisement.value == null &&
        adEngine.value == "gladi8tor"
      ) {
        store.dispatch(ActionTypes.GetAdvertisement, props.spot);
      }
    });

    const checkScrollVisible = () => {
      if (ad.value == null) {
        return;
      }

      const elm = ad.value;
      let threshold = ad.value.offsetHeight / 2;
      let mode = "visible";

      if (elm == undefined) return false;
      threshold = threshold || 0;
      mode = mode || "visible";
      let rect = elm.getBoundingClientRect();
      let viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      let above = rect.bottom - threshold < 0;
      let below = rect.top - viewHeight + threshold >= 0;
      let vis =
        mode === "above" ? above : mode === "below" ? below : !above && !below;

      if (vis) {
        adPropVisible.visible = vis;
        window.removeEventListener("scroll", checkScrollVisible);
      }
    };

    window.addEventListener("scroll", checkScrollVisible);

    onBeforeUnmount(() =>
      window.removeEventListener("scroll", checkScrollVisible)
    );
    onMounted(() => checkScrollVisible());
    watchEffect(() => {
      if (adEngine.value == "imm" && imm.value != null) {
        // eslint-disable-next-line
        if ((window as any).PXEasy != null) {
          // eslint-disable-next-line
          (window as any).PXEasy.build(ad.value);
        }
      }
    });

    return {
      t,
      store,
      adEngine,
      advertisement,
      pointer,
      ad,
      route,
      adPropVisible,
      imm,
    };
  },
  computed: {
    // eslint-disable-next-line
    pxZoneID: (e: any) => {
      switch (e.$props.spot) {
        case AdvertisementSpots.HomeTop:
        case AdvertisementSpots.GamesTop:
        case AdvertisementSpots.StreamsTop:
        case AdvertisementSpots.StreamVideoPreRoll:
        case AdvertisementSpots.NewsTop:
        case AdvertisementSpots.ShopTop:
          return "1";

        case AdvertisementSpots.HomeMiddle:
        case AdvertisementSpots.GamesMiddle:
          return "2";

        case AdvertisementSpots.HomeFooter:
        case AdvertisementSpots.GamesFooter:
        case AdvertisementSpots.GameFooter:
        case AdvertisementSpots.StreamsFooter:
        case AdvertisementSpots.StreamFooter:
        case AdvertisementSpots.NewsFooter:
        case AdvertisementSpots.NewsItemFooter:
        case AdvertisementSpots.ShopFooter:
        case AdvertisementSpots.ShopItemFooter:
          return "4";

        default:
          return "1";
      }
    },
    imdZoneID: (e: any) => {
      switch (e.$props.spot) {
        case AdvertisementSpots.HomeTop:
        case AdvertisementSpots.GamesTop:
        case AdvertisementSpots.StreamsTop:
        case AdvertisementSpots.StreamVideoPreRoll:
        case AdvertisementSpots.NewsTop:
        case AdvertisementSpots.ShopTop:
          return "1";

        case AdvertisementSpots.HomeMiddle:
        case AdvertisementSpots.GamesMiddle:
          return "2";

        case AdvertisementSpots.HomeFooter:
        case AdvertisementSpots.GamesFooter:
        case AdvertisementSpots.GameFooter:
        case AdvertisementSpots.StreamsFooter:
        case AdvertisementSpots.StreamFooter:
        case AdvertisementSpots.NewsFooter:
        case AdvertisementSpots.NewsItemFooter:
        case AdvertisementSpots.ShopFooter:
        case AdvertisementSpots.ShopItemFooter:
          return "3";

        default:
          return "1";
      }
    },
    // eslint-disable-next-line
    pxSection: (e: any) => {
      switch (e.$props.spot) {
        case AdvertisementSpots.HomeTop:
        case AdvertisementSpots.HomeMiddle:
        case AdvertisementSpots.HomeFooter:
          return "gaming_home";

        case AdvertisementSpots.GamesTop:
        case AdvertisementSpots.GamesMiddle:
        case AdvertisementSpots.GamesFooter:
        case AdvertisementSpots.GameFooter:
          return "gaming_games";

        case AdvertisementSpots.StreamsTop:
        case AdvertisementSpots.StreamsFooter:
        case AdvertisementSpots.StreamVideoPreRoll:
        case AdvertisementSpots.StreamFooter:
          return "gaming_streams";

        case AdvertisementSpots.NewsTop:
        case AdvertisementSpots.NewsFooter:
        case AdvertisementSpots.NewsItemFooter:
          return "gaming_news";
        case AdvertisementSpots.ShopTop:
        case AdvertisementSpots.ShopFooter:
        case AdvertisementSpots.ShopItemFooter:
          return "gaming_shop";

        default:
          return "gaming_home";
      }
    },
    imdSection: (e: any) => {
      switch (e.$props.spot) {
        case AdvertisementSpots.HomeTop:
        case AdvertisementSpots.HomeMiddle:
        case AdvertisementSpots.HomeFooter:
          return "c1cae802-cbe4-48fe-a44c-7c180b2ed538";

        case AdvertisementSpots.GamesTop:
        case AdvertisementSpots.GamesMiddle:
        case AdvertisementSpots.GamesFooter:
        case AdvertisementSpots.GameFooter:
          return "8eb748b9-084d-49fe-8bbc-340dd81504d9";

        case AdvertisementSpots.StreamsTop:
        case AdvertisementSpots.StreamsFooter:
        case AdvertisementSpots.StreamVideoPreRoll:
        case AdvertisementSpots.StreamFooter:
          return "998fb2c7-8852-4f3a-bc1e-eb3893cfe7ec";

        case AdvertisementSpots.NewsTop:
        case AdvertisementSpots.NewsFooter:
        case AdvertisementSpots.NewsItemFooter:
          return "919bf7b3-f7b2-4aaf-ac3d-15cb974faf06";
        case AdvertisementSpots.ShopTop:
        case AdvertisementSpots.ShopFooter:
        case AdvertisementSpots.ShopItemFooter:
          return "ccdb3619-679a-47c8-827d-6f81e994129e";

        default:
          return "c1cae802-cbe4-48fe-a44c-7c180b2ed538";
      }
    },
  },
  methods: {
    openMicroSite() {
      if (
        this.advertisement != undefined &&
        this.advertisement.microSiteURI.length > 0
      ) {
        this.$router.push({
          name: "Frame",
          params: { uri: this.advertisement.microSiteURI },
        });
      }
    },
    adLoaded() {
      this.store.dispatch(ActionTypes.AdvertisementViewed, {
        spot: this.$props.spot,
        advertisement: this.advertisement,
        route: this.route,
      } as AdvertisementViewedPayload);
    },
  },
});
