export type News = {
  id: string;
  author: string;
  description: string;
  thumbnailUri: string;
  publishedAt: string;
  title: string;
};
export type Advertisement = {
  id: string;
  spot: string;
  advertisement: string;
  microSiteURI: string;
  extension: string;
  uri: string;
};

export type Stream = {
  id: string;
  annotations: string[];
  title: string;
  subTitle: string;
  uri: string;
  description: string;
};

export type StreamCategory = {
  annotations: string[];
  title: string;
  subTitle: string;
  large: Stream;
  items: Stream[];
};

export type Category = {
  name: string;
  games: Game[];
}

export type Game = {
  id: string;
  description: string;
  orientation: string | undefined;
  hideFullscreen: HideFullscreen;
  annotations: string[];
  category: string;
  name: string;
  thumbnail: Thumbnail;
  entryPoint?: string;
};

export type HideFullscreen = {
  iOS: boolean;
  iPad: boolean;
  android: boolean;
  desktop: boolean;
};

export type Shop = {
  id: string;
  description: string;
  highlightedImage: string;
  image: string;
  price: number;
  title: string;
  tags: string[];
  variantID: string;
  category: string[];
};

export type Order = {
  id: string;
  quantity: number;
}

export type Thumbnail = {
  landscape: string;
  portrait: string;
};

export type ShoppingCart = {
  items: ShoppingCartItem[];
  price: number;
  itemCount: number;
}

export type ShoppingCartItem = {
  item: Shop;
  amount: number;
  price: number;
}

export type FlightData = {
  flightNumber: string;
  origin: string;
  destination: string;
  airline: string;
}

export type State = {
  loading: boolean;
  version: string | undefined;
  flightData : FlightData | undefined;

  // Games
  games: Game[];
  alsoFunGames: { [key: string]: Game[] | undefined };
  bestRatedGames: Game[];
  trendingGame: Game | undefined;
  gamesByCategory: Category[] | undefined;

  // Streams
  streams: { [key: string]: Stream | undefined };
  latestStreams: Stream[] | undefined;
  alsoFunStreams: { [key: string]: Stream[] | undefined };
  trendingStream: Stream | undefined;

  // Shop
  trendingShop: Shop | undefined;
  bestSellersShop: Shop[] | undefined;
  staffPickShop: Shop[] | undefined;
  megaDealShop: Shop | undefined;
  shopItems: { [key: string]: Shop | undefined };
  shoppingCart: ShoppingCart;
  shopOverlay: boolean,

  // News
  trendingNews: News | undefined;
  latestNews: News[] | undefined;
  moreNews: News[] | undefined;
  news: { [key: string]: News | undefined };
  recommendedNews: { [key: string]: News[] | undefined };

  // Advertisements
  advertisement: { [key: string]: Advertisement | undefined };
  advertisementEngine: { [key: string]: string | undefined };

  // Analytics
  sessionStart: Date | undefined;
};

export const state: State = {
  version: undefined,
  loading: false,
  flightData: undefined,

  // Games
  games: [],
  trendingGame: undefined,
  bestRatedGames: [],
  alsoFunGames: {},
  gamesByCategory: undefined,

  // Streams
  trendingStream: undefined,
  latestStreams: [],
  streams: {},
  alsoFunStreams: {},

  // Shop
  trendingShop: undefined,
  bestSellersShop: [],
  staffPickShop: [],
  megaDealShop: undefined,
  shopItems: {},
  shoppingCart: (localStorage.getItem('shopping-cart') != undefined ? JSON.parse(localStorage.getItem('shopping-cart') as string) :  {
    itemCount: 0,
    items: [],
    price: 0,
  }),
  shopOverlay: false,

  // News
  trendingNews: undefined,
  latestNews: [],
  moreNews: [],
  news: {},
  recommendedNews: {},

  // Advertisements
  advertisement: {},
  advertisementEngine: {},

  // Analytics
  sessionStart: undefined,
};
